// Stylesheets
require('stylesheets/site.sass')

// Require defaults
require("@rails/ujs").start()
require("turbolinks").start()

// Font Awesome
import "src/site/fontawesome"

// Stimulus
// import "controllers/site"
